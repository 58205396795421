import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { CurrentUser } from "./Helpers/CurrentUser";
import { Layout } from "./Pages/Layout/Layout";
import { Login } from "./Pages/Login/Login";
import { Municipalities } from "./Pages/Municipalities/Municipalities";
import { SessionViewer } from "./Pages/SessionViewer/SessionViewer";
import { Sessions } from "./Pages/Sessions/Sessions";
import { SharedSessionViewer } from "./Pages/SharedSessionViewer/SharedSessionViewer";
import { ResetPassword } from "./Pages/ResetPassword/ResetPassword";
import { useEffect } from "react";
import { Profile } from "./Pages/Profile/Profile";
import { LoginBody } from "./Components/Login/LoginBody";
import { RegisterBody } from "./Components/Login/RegisterBody";
import { ForgottenPasswordBody } from "./Components/Login/ForgottenPasswordBody";
import { SessionsImproved } from "./Pages/SessionsImproved/SessionsImproved";

function App() {

  const userToken = CurrentUser.getToken();

  useEffect(() => {
    if (process.env.REACT_APP_BUILD !== "") {
      document.title = "(" + process.env.REACT_APP_BUILD + ") ";
    } else { document.title = ""; }
    document.title += " PinPoint Web";

  }, []);

  if (!userToken) {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<Login />}>
            <Route path="login" element={<LoginBody />} />
            <Route path="register" element={<RegisterBody />} />
            <Route path="forgottenpassword" element={<ForgottenPasswordBody />} />
          </Route>
          <Route path="sharedSession" element={<SharedSessionViewer />} />
          <Route path="reset/*" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<SessionsImproved />} />
        <Route path="inprogress" element={<SessionViewer />} />
        <Route path="sessions" element={<SessionsImproved />} />
        <Route path="sessions/:pingSessionId" element={<SessionsImproved />} />
        <Route element={<Layout />}>
          <Route path="municipalities" element={<Municipalities />} />
          <Route path="profile" element={<Profile />} />
        </Route>

        <Route element={<Login />}>
          <Route path="login" element={<LoginBody />} />
          <Route path="register" element={<RegisterBody />} />
        </Route>
        <Route path="sharedSession" element={<SharedSessionViewer />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
