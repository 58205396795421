import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../Api/UserApi";
import { CurrentUser } from "../../Helpers/CurrentUser";
import { Button, Container, Form } from 'react-bootstrap';

export const LoginBody = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Check if newUser=true is in the URL
        const params = new URLSearchParams(window.location.search);
        const newUser = params.get("newUser");
    
        if (newUser) {
          setError("Registration successful. Please login.");
        }
    
        if (params.get("expired")) {
          setError("You have been logged out as your session has expired. Please relogin.");
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");
    
        login(email, password)
          .then((response) => {
            console.log("response", response);
    
            CurrentUser.saveToken(response.data);
    
            // Navigate to the home page
            navigate("/sessions")
          })
          .catch((error) => {
            const responseCode = error.response ? error.response.status : null;
            if (responseCode === 401) {
              setError(error.response.data);
            } else {
              setError("An error occurred");
            }
    
            setLoading(false);
          });
      };
    
    return <><h2>Login to PinPoint</h2>
        <p>PinPoint is a tool for sharing live locations securely.</p>
        <p>Enter your email address and password to login.</p>
        <Form>
            <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                />
            </Form.Group>

            <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                />
            </Form.Group>
            <br />
            <Button
                variant="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
                style={{ marginRight: "10px" }}
            >
                {loading && (
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                )}
                {!loading && "Login"}
            </Button>

            <Button
                variant="danger"
                onClick={() => {
                    navigate("/register");
                }}
                style={{ marginRight: "10px" }}
            >
                Register
            </Button>

            <Button variant='warning'
                onClick={() => {
                    navigate("/forgottenpassword")
                }}>
                Forgotten Password
            </Button>

            {error && <p style={{ color: "red" }}>{error}</p>}
        </Form></>
};