import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { CurrentUser } from "../../Helpers/CurrentUser";
import "./PinPointNavbar.css";
import { useEffect, useState } from "react";
import { userInformation } from "../../Api/UserApi";
import ProfilePicture from "../ProfilePicture";

function PinPointNavbar({ onBackClick, showBackButton }) {
  const navigate = useNavigate();
  const [currentUserInfo, setCurrentUserInfo] = useState({});

  const handleLogout = () => {
    CurrentUser.removeToken();
    navigate("/login");
  };

  const handleNavClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    userInformation().then((response) => {
      setCurrentUserInfo(response.data);
    });
  }, [CurrentUser.getToken()]);

  return (
    <Navbar expand="lg" className="bg-body-tertiary navbar">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src="/icons8-point-stickers-32.png" alt="PinPoint logo" />
          PinPoint
          {process.env.REACT_APP_BUILD !== "" && (
            <span className="environment"> ({process.env.REACT_APP_BUILD})</span>
          )}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {showBackButton && (
              <Nav.Link 
                onClick={onBackClick} 
                style={{ color: 'white', cursor: 'pointer' }}
              >
                &larr; Back to Sessions
              </Nav.Link>
            )}
            {/* <Nav.Link 
              onClick={() => handleNavClick("/municipalities")} 
              style={{ color: 'white', cursor: 'pointer' }}
            >
              Municipalities
            </Nav.Link>
            <Nav.Link 
              onClick={() => handleNavClick("/sessions")} 
              style={{ color: 'white', cursor: 'pointer' }}
            >
              Manage Sessions
            </Nav.Link> */}
            <Nav.Link href="/apk/pinpoint.apk" style={{ color: 'white', cursor: 'pointer' }}>
              Download Apk
            </Nav.Link>
          </Nav>
          <Nav>
            <ProfilePicture userId={currentUserInfo.userId} className="profile-picture-small" alt="Profile picture" />
            <NavDropdown 
              title="Account" 
              id="account-dropdown"
              align="end"
              className="custom-dropdown"
              menuVariant="dark"
            >
              <NavDropdown.Item as={Link} to="/profile">Profile</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default PinPointNavbar;