export class CurrentUser {
  // Key to use when saving/retrieving token from localStorage
  static TOKEN_KEY = 'pinpoint_token';
  static USER_ID_KEY = 'pinpoint_user_id';

  // Save token to localStorage
  static saveToken(token, userId) {
    localStorage.setItem(CurrentUser.TOKEN_KEY, token);
    localStorage.setItem(CurrentUser.USER_ID_KEY, userId);
  }

  // Retrieve token from localStorage
  static getToken() {
    return localStorage.getItem(CurrentUser.TOKEN_KEY);
  }

  // Remove token from localStorage
  static removeToken() {
    localStorage.removeItem(CurrentUser.TOKEN_KEY);
    localStorage.removeItem(CurrentUser.USER_ID_KEY);
  }
}