import React, { createContext, useState, useContext } from 'react';
import PinPointModal from './PinPointModal';

// Create the Modal Context
const ModalContext = createContext();

// Modal Provider Component
export const ModalProvider = ({ children }) => {
  const [modalConfig, setModalConfig] = useState({
    show: false,
    title: '',
    content: null,
    size: undefined,
    centered: false,
    confirmText: 'Save',
    cancelText: 'Cancel',
    onConfirm: () => {},
    showFooter: true,
  });

  // Function to open the modal
  const openModal = (config) => {
    setModalConfig({
      ...config,
      show: true,
    });
  };

  // Function to close the modal
  const closeModal = () => {
    setModalConfig({
      ...modalConfig,
      show: false,
    });
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      
      <PinPointModal
        show={modalConfig.show}
        onHide={closeModal}
        title={modalConfig.title}
        size={modalConfig.size}
        centered={modalConfig.centered}
        confirmText={modalConfig.confirmText}
        cancelText={modalConfig.cancelText}
        onConfirm={modalConfig.onConfirm}
        showFooter={modalConfig.showFooter}
      >
        {modalConfig.content}
      </PinPointModal>
    </ModalContext.Provider>
  );
};

// Custom hook to use the modal context
export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};