import axios from "axios";
import { CurrentUser } from "../Helpers/CurrentUser";

export const login = async (email, password) => {
	return axios
		.post(process.env.REACT_APP_SERVER_URL + "/user/login", { email, password });
}

export const register = async (email, password) => {
	return axios
		.post(process.env.REACT_APP_SERVER_URL + "/user/create", { email, password });
}

export const forgottenPassword = async (email) => {
	return axios.post(process.env.REACT_APP_SERVER_URL + "/user/forgotten", { email });
}

export const verifyResetRequest = async (resetToken) => {
	return axios.get(process.env.REACT_APP_SERVER_URL + "/user/verifyresetrequest?resetToken=" + resetToken);	
}

export const resetPassword = async (password, resetToken) => {
	return axios.post(process.env.REACT_APP_SERVER_URL + "/user/ResetPassword",{password, resetToken})
}

export const userInformation = async() => {
	return axios.get(process.env.REACT_APP_SERVER_URL + "/user/GetCurrentUserInformation", {headers: {"Authorization":CurrentUser.getToken()}})
}

export const getProfilePicture = async (userId) => {
    return axios.get(process.env.REACT_APP_SERVER_URL + "/user/GetProfilePicture", {
        params: { userId },
        headers: { 
            "Authorization": CurrentUser.getToken() 
        },
        responseType: 'blob' // Important: tells axios to handle the response as binary data
    });
}

export const updateProfile = async (username, profilePicture) => {
	const formData = new FormData();
	formData.append("username", username);
	if (profilePicture) {
		formData.append("profilePicture", profilePicture);
	}

	return axios.post(process.env.REACT_APP_SERVER_URL + "/user/UpdateProfileDetails", formData, {
		headers: {
			"Authorization": CurrentUser.getToken(),
			"Content-Type": "multipart/form-data"
		}
	});
}

// Helper function to create a URL for the image
export const createProfilePictureUrl = (blob) => {
    return URL.createObjectURL(blob);
}

export const createProfilePictureUrl2 = (blob) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result); // This is a data URL like "data:image/jpeg;base64,..."
        };
        reader.readAsDataURL(blob);
    });
};