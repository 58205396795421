
import olView from "ol/View";
import Map from "../../MapComponents/Map/Map";
import osm from "../../MapComponents/Source/osm";
import { memo, useEffect, useMemo, useState } from "react";
import { fromLonLat } from "ol/proj";
import TileLayer from "../../MapComponents/Layers/TileLayer";
import Layers from "../../MapComponents/Layers/Layers";
import PinPointNavbar from "../../Components/PinPointNavbar/PinPointNavbar";
import { Container } from "react-bootstrap";
import { Sessions } from "../Sessions/Sessions";
import { getPingSession } from "../../Api/PingSessionApi";
import { Feature } from "ol";
import { Point } from "ol/geom";
import { Fill, Icon, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import VectorLayer from "../../MapComponents/Layers/VectorLayer";
import MyVectorSource from "../../MapComponents/Source/VectorSource";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Overlays from "../../MapComponents/Overlays/Overlays";
import PinPointInfoOverlay from "../../MapComponents/Overlays/PinPointInfoOverlay";
import { LoadingSpinner } from "../../Components/LoadingSpinner/LoadingSpinner";

export const SessionsImproved = () => {
    const [view, setView] = useState(null);
    const [showSessionsModal, setShowSessionsModal] = useState(false);
    const [features, setFeatures] = useState([]);
    const [infoOverlayFeature, setInfoOverlayFeature] = useState(null);
    const { pingSessionId } = useParams();
    const [isViewInitialized, setIsViewInitialized] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        setView(new olView({
            center: fromLonLat([-0.099912, 51.513671]),
            zoom: 16,
        }));
        setIsViewInitialized(true);
    }, []);

    useEffect(() => {
        if (!isViewInitialized) return;
        if (pingSessionId) {
            setShowSessionsModal(false);
            loadPingSession(pingSessionId);
        } else {
            // If we're at the base route without a session ID, show the sessions modal
            setShowSessionsModal(true);
        }
    }, [pingSessionId, isViewInitialized]);

    const loadPingSession = (id) => {
        getPingSession(id).then((pingSession) => {
            const builtFeatures = pingSession.pings.map((ping, index) => {
                let point = new Point(
                    fromLonLat([
                        ping.position.coordinates[1],
                        ping.position.coordinates[0],
                    ])
                );
                let feature = new Feature(point);
                feature.set("id", ping.pingId);
                feature.set("createDate", ping.createDate);
                feature.set("pingSessionId", ping.pingSessionId);
                feature.set("speed", ping.speedKilometresPerHour);
                feature.set("acceleration", ping.accelerationMetresPerSecond);
                feature.set("index", index);
                feature.set("totalPings", pingSession.pings.length);
                return feature;
            });

            setFeatures(builtFeatures);

            if (builtFeatures.length > 0) {
                let minX = Infinity,
                    minY = Infinity,
                    maxX = -Infinity,
                    maxY = -Infinity;

                for (let i = 0; i < builtFeatures.length; i++) {
                    const f = builtFeatures[i];
                    const cords = f.getGeometry().flatCoordinates;
                    const x = cords[0];
                    const y = cords[1];
                    if (x < minX) minX = x;
                    if (y < minY) minY = y;
                    if (x > maxX) maxX = x;
                    if (y > maxY) maxY = y;
                }

                const buffer = 2000;
                const bufferedExtent = [
                    minX - buffer,
                    minY - buffer,
                    maxX + buffer,
                    maxY + buffer,
                ];
                // Zoom to the extent
                view && view.fit(bufferedExtent, { duration: 1000 });
            }

            setLoadingMessage(undefined);
        });
    }

    const handleSessionSelected = (pingSessionId) => {
        navigate(`/sessions/${pingSessionId}`);
    }

    const handleNextFeatureClick = (feature, direction) => {
        // find index of existing feature by Id
        const index = features.findIndex((f) => f.get("id") === feature.get("id"));

        const nextFeature = features[index + direction];
        setInfoOverlayFeature(nextFeature);

        view.setCenter(nextFeature.getGeometry().getCoordinates());
    };

    const handlePointerClick = (e) => {
        const map = e.map;
        const features = map.getFeaturesAtPixel(e.pixel, {
            hitTolerance: 4,
        });
        const pointFeatures = features.filter(
            (feature) => feature.getGeometry() instanceof Point
        );

        if (pointFeatures.length > 0) {
            setInfoOverlayFeature(pointFeatures[0]);
        } else {
            setInfoOverlayFeature(null);
        }
    };

    const featureStyle = useMemo(() => {
        let genericPointCache = undefined;
        let arrivalCache = undefined;
        let departureCache = undefined;

        if (!genericPointCache) {
            genericPointCache = new Style({
                image: new CircleStyle({
                    radius: 4,
                    fill: new Fill({
                        color: "#8255a6",
                    }),
                    stroke: new Stroke({
                        color: "#643787",
                        width: 1,
                    }),
                    zIndex: 999,
                })
            })

            arrivalCache = new Style({
                image: new Icon({
                    anchor: [0.5, 20],
                    anchorXUnits: "fraction",
                    anchorYUnits: "pixels",
                    src: "start.png",
                    zIndex: 1000,
                }),
            });

            departureCache = new Style({
                image: new Icon({
                    anchor: [0.5, 20],
                    anchorXUnits: "fraction",
                    anchorYUnits: "pixels",
                    src: "flag.png",
                    zIndex: 1000,
                }),
            });
        }

        return (feature) => {
            if (feature.getGeometry() instanceof Point) {
                const index = feature.get("index");
                const totalPings = feature.get("totalPings");

                if (totalPings > 2) {
                    if (index === 0) {
                        return departureCache;
                    } else if (index === totalPings - 1) {
                        return arrivalCache;
                    } else {
                        return genericPointCache;
                    }
                }
                else {
                    return genericPointCache;
                }
            }
        }
    }, []);

    return (<>
        <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
            <PinPointNavbar />
            {loadingMessage && <LoadingSpinner Message={loadingMessage} />}
            {showSessionsModal && <Container style={{
                maxWidth: "1200px",
                position: "fixed",
                top: "100px",
                left: "50%",
                transform: "translate(-50%, 0%)",
                zIndex: "10", // This ensures the container stays above the map
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 20px 16px 0 rgba(0,0,0,0.4)",
                height: "80%",
                //backgroundColor: "#393040",
                //color: "white"
                overflowY: "auto"
            }}>
                <Sessions onView={handleSessionSelected} />
            </Container>}
            <div className="sessionViewMapContainer">
                <Map view={view} onClick={handlePointerClick}>
                    <Layers>
                        <TileLayer source={osm()} zIndex={0} />
                        <VectorLayer style={featureStyle} zIndex={2}>
                            <MyVectorSource features={features} />
                        </VectorLayer>
                    </Layers>
                    <Overlays>
                        <PinPointInfoOverlay
                            feature={infoOverlayFeature}
                            onNextFeatureClick={handleNextFeatureClick}
                        />
                    </Overlays>
                </Map>
            </div></div></>
    )
}