import React, { useEffect, useState } from 'react';
import olView from "ol/View";
import { Button, Container, Form } from 'react-bootstrap';
import { fromLonLat } from "ol/proj";
import Layers from "../../MapComponents/Layers/Layers";
import TileLayer from "../../MapComponents/Layers/TileLayer";
import VectorLayer from "../../MapComponents/Layers/VectorLayer";
import Map from "../../MapComponents/Map/Map";
import osm from "../../MapComponents/Source/osm";
import { LoginBody } from '../../Components/Login/LoginBody';
import { Outlet } from 'react-router-dom';

export const Login = () => {
  const [view, setView] = useState(null);

  useEffect(() => {
    setView(new olView({
      center: fromLonLat(getRandomUkLocation()),
      zoom: 16,
  }));
  }, []);
  
  const getRandomUkLocation = () => {
    const potentials = [
     // [-0.38466814, 51.404773],
      [-1.1555332, 50.652712],
      //[-3.7364030, 52.796625],
      //51.513671, -0.099912
      [-0.099912, 51.513671],
    ]

    return potentials[Math.floor(Math.random() * potentials.length)];
  }

  return (<>
  <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
    <Container style={{
      maxWidth: "400px",
      position: "fixed",
      top: "29%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: "10", // This ensures the container stays above the map
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 20px 16px 0 rgba(0,0,0,0.4)",
      backgroundColor:"#393040",
      color:"white"
    }}>
      
    <Outlet />
    </Container>
    <div className="sessionViewMapContainer">
      <Map view={view} pannable={false}>
        <Layers>
          <TileLayer source={osm()} zIndex={0} />
        </Layers>
      </Map>
    </div></div></>
  );
};
