import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import PinPointNavbar from "../../Components/PinPointNavbar/PinPointNavbar";
import { ModalProvider } from "../../Components/ModalContext";

export const Layout = () => {
  return (
    <>
      <ModalProvider>
        <PinPointNavbar />
        <Container style={{ paddingTop: 15 }}>
          <Outlet />
        </Container>
      </ModalProvider>
    </>
  );
};
