import { useEffect, useMemo, useState } from "react";
import { updateProfile, userInformation } from "../../Api/UserApi";
import ProfilePicture from "../../Components/ProfilePicture";
import PinPointModal from "../../Components/PinPointModal";
import { Button, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import { EditProfileModal } from "../../Modals/EditProfileModal";
import { useModal } from "../../Components/ModalContext";
import context from "react-bootstrap/esm/AccordionContext";

export const Profile = () => {
    const { openModal, closeModal } = useModal();
    const [userInfo, setUserInfo] = useState({});
    const [loading, setLoading] = useState(true);

    const handleProfileEditSubmit = (values) => {
        console.log("Submitting profile edit form with values:", values);
        updateProfile(values.username, values.profilePicture).then(() => {
            // Refresh the page
            window.location.reload();
        });
    }

    const editProfileModal = useMemo(() => {
        return <Formik
            initialValues={{ username: userInfo.username, profilePicture: undefined }}
            onSubmit={(values) => { handleProfileEditSubmit(values); }}>
            {({ values, handleChange, handleSubmit, setFieldValue }) =>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Username</Form.Label>
                        <Form.Control name="username" type="text" onChange={handleChange} placeholder="Enter username" value={values.username} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Profile Picture</Form.Label>
                        <Form.Control name="profilePicture" type="file" onChange={(event) => {
                            // Get the actual file object
                            const file = event.currentTarget.files[0];
                            setFieldValue("profilePicture", file);
                        }} />
                    </Form.Group>

                    <div className="d-flex gap-2 justify-content-end mt-3">
                        <Button variant="secondary" onClick={() => { closeModal(); }}>Cancel</Button>
                        <Button variant="primary" type="submit">Save</Button>
                    </div>
                </Form>}
        </Formik>
    }, [userInfo]);

    useEffect(() => {
        userInformation().then((data) => {
            setUserInfo(data.data);
        });
    }, []);

    const openEditProfileModal = () => {
        openModal({
            title: "Edit Profile",
            content: editProfileModal,
            showFooter: false,
            centered:true
        });
    }


    return <>
        <h1>Profile Page</h1>
        <b>Email: </b><span>{userInfo.email}</span><br />
        <b>Username: </b><span>{userInfo.username}</span><br />
        <b>Profile Picture:</b><br />
        <ProfilePicture userId={userInfo.userId} className="profile-picture" alt="Profile picture" />
        <br /><br />
        <Button onClick={openEditProfileModal} variant="warning" >Edit Profile</Button>
    </>
}