import React, { useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { register } from '../../Api/UserApi'; // You might need to create this API method

export const RegisterBody = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Basic password match validation
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        setLoading(true);
        setError('');

        register(email, password).then((response) => {
            console.log('response', response);

            // Navigate to the login page after successful registration or take any other desired action
            window.location.href = '/login?newUser=true';

        }).catch((error) => {
            const responseCode = error.response ? error.response.status : null;
            if (responseCode === 400) {
                setError(error.response.data); // Assuming 400 contains an error message from your API
            } else {
                setError('An error occurred');
            }

            setLoading(false);
        });
    }

    return <><h2>Register for PinPoint</h2>
    <p>Join PinPoint and start managing sessions.</p>
    <Form>
        <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Group>

        <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </Form.Group>

        <Form.Group controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </Form.Group>
        <br />
        <Button variant="primary" type="submit" onClick={handleSubmit} disabled={loading}>
            {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
            {!loading && 'Register'}
        </Button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
    </Form></>;
}