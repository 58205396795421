import { Feature } from "ol";
import { createEmpty, extend } from 'ol/extent';
import olView from "ol/View";
import {  Point } from "ol/geom.js";
import { fromLonLat } from "ol/proj";
import { Fill, Icon, Stroke, Style, Text } from "ol/style.js";
import CircleStyle from "ol/style/Circle.js";
import { useEffect, useMemo, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { getSharedPingSession } from "../../Api/PingSessionApi.js";
import { useInterval } from "../../Hooks/UseInterval.js";
import Layers from "../../MapComponents/Layers/Layers";
import TileLayer from "../../MapComponents/Layers/TileLayer";
import VectorLayer from "../../MapComponents/Layers/VectorLayer";
import Map from "../../MapComponents/Map/Map";
import MyVectorSource from "../../MapComponents/Source/VectorSource";
import osm from "../../MapComponents/Source/osm";
import "../SessionViewer/SessionViewer.css";
import { createProfilePictureUrl2, getProfilePicture, userInformation } from "../../Api/UserApi.js";

export const SharedSessionViewer = () => {
    const [sharedSessionId, setSharedSessionId] = useState(null);
    const [autoUpdate, setAutoUpdate] = useState(true);
    const [autoCenter, setAutoCenter] = useState(true);
    const [infoOverlayFeature, setInfoOverlayFeature] = useState(null);
    const [autoDrawLines, setAutoDrawLines] = useState(false);
    const [performanceDraw, setPerformanceDraw] = useState(true);
    const [features, setFeatures] = useState([]);
    const [displayPoints, setDisplayPoints] = useState(true);
    const [username, setUsername] = useState("");
    const [sessionEnded, setSessionEnded] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [location, setLocation] = useState(null);
    const [hideTopBar, setHideTopBar] = useState(true);
    const [focusBoth, setFocusBoth] = useState(true);
    const [targetUserId, setTargetUserId] = useState(null);
    const [osmLayer, setOsmLayer] = useState(null);
    const [targetLayer, setTargetLayer] = useState(null);
    const [currentLayer, setCurrentLayer] = useState(null);
    const [currentUserStyle, setCurrentUserStyle] = useState(null);
    const [targetUserStyle, setTargetUserStyle] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const loadSharedSession = async (sharedSessionId) => {
            setSharedSessionId(sharedSessionId);
            let getPingSessionData = await getSharedPingSession(sharedSessionId);
            setTargetUserId(getPingSessionData.userId);
            console.log("Ping session data: ", getPingSessionData);

            let targetUserPicture = null;
            let currentUserPicture = null;

            try {
                targetUserPicture = await createProfilePictureUrl2(((await getProfilePicture(getPingSessionData.userId)).data));
            }
            catch {

            }

            try {
                let currentUserData = await userInformation();
                console.log("Current user data: ", currentUserData);
                let currentUserId = currentUserData.data.userId;
                currentUserPicture = await createProfilePictureUrl2((await getProfilePicture(currentUserId)).data);
            }
            catch {

            }

            console.log("Target user picture: ", targetUserPicture);
            console.log("Current user picture: ", currentUserPicture);

            setOsmLayer(<TileLayer source={osm()} zIndex={0} />);

            const builtFeatures = getPingSessionData.pings.map((ping, index) => {
                let point = new Point(
                    fromLonLat([
                        ping.position.coordinates[1],
                        ping.position.coordinates[0],
                    ])
                );
                let feature = new Feature(point);
                feature.set("id", ping.pingId);
                return feature;
            });

            setFeatures(builtFeatures);

            const getImageStyle = (picture, text) => {
                return new Style({
                    image: new Icon({
                        src: picture,
                        width: 70,
                        height: 70,
                        anchor: [0.5, 0.5],
                        stroke: new Stroke({
                            color: 'black',
                            width: 3
                        }),
                    }),
                    text: new Text({
                        text: text,
                        offsetY: 40, // Position the text below the circle
                        font: '24px Arial',
                        fill: new Fill({
                            color: 'white'
                        }),
                        stroke: new Stroke({
                            color: 'black',
                            width: 2
                        })
                    })
                });
            }
            console.log("Ping sesion data", getPingSessionData)
            let targetUserStyleLocal = new Style({
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: "red",
                    }),
                    stroke: new Stroke({
                        color: "black",
                        width: 1,
                    }),
                    radius: 8
                }),
                text: new Text({
                    text: getPingSessionData.username,
                    offsetY: 24, // Position the text below the circle
                    font: '24px Arial',
                    fill: new Fill({
                        color: 'white'
                    }),
                    stroke: new Stroke({
                        color: 'black',
                        width: 2
                    })
                })
            });

            if (targetUserPicture !== null) {
                targetUserStyleLocal = getImageStyle(targetUserPicture, getPingSessionData.username);
            }
            setTargetUserStyle(targetUserStyleLocal);

            let currentUserImageStyle = new Style({
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: "blue",
                    }),
                    stroke: new Stroke({
                        color: "black",
                        width: 1,
                    }),
                    radius: 4
                }),
                text: new Text({
                    text: "You",
                    offsetY: 24, // Position the text below the circle
                    font: '18px Arial',
                    fill: new Fill({
                        color: 'white'
                    }),
                    stroke: new Stroke({
                        color: 'black',
                        width: 2
                    })
                })
            });
            if (currentUserPicture !== null) {
                currentUserImageStyle = getImageStyle(currentUserPicture, "You");
            }
            setCurrentUserStyle(currentUserImageStyle);

            const latestFeature = builtFeatures[0];
            centerView(latestFeature);

            getLocation();

        };

        if (urlParams.has("sharedSessionId")) {
            loadSharedSession(urlParams.get("sharedSessionId"));
        }

    }, [window.location.search]);

    useEffect(() => {
        if (currentUserStyle) {
            setCurrentLayer(
                <VectorLayer style={currentUserStyle} zIndex={2}>
                    <MyVectorSource features={location ? [location.pointFeature] : []} />
                </VectorLayer>
            );
        }
    }, [currentUserStyle, location]);

    useEffect(() => {
        if (targetUserStyle) {
            setTargetLayer(
                <VectorLayer style={targetUserStyle} zIndex={2}>
                    <MyVectorSource features={features} />
                </VectorLayer>
            );
        }
    }, [targetUserStyle, features]);

    const view = useMemo(
        () =>
            new olView({
                center: fromLonLat([-0.38466814, 51.404773]),
                zoom: 16,
            }),
        []
    );

    useInterval(
        () => {
            loadPings(autoCenter);
        },
        autoUpdate && !sessionEnded ? 5000 : null
    );

    const loadPings = async (centerLatest = false, centerAll = false) => {
        console.log("Center all", centerAll);
        getSharedPingSession(sharedSessionId).then((pingSession) => {
            if (!targetUserId) {
                setTargetUserId(pingSession.userId);
            }
            const builtFeatures = pingSession.pings.map((ping, index) => {
                let point = new Point(
                    fromLonLat([
                        ping.position.coordinates[1],
                        ping.position.coordinates[0],
                    ])
                );
                let feature = new Feature(point);
                return feature;
            });

            setLastUpdated(pingSession.pings[0].createDate);
            setUsername(pingSession.userEmail);
            setSessionEnded(pingSession.endDateDisplay);

            if (performanceDraw) {
                setFeatures(builtFeatures.slice(0, 1));
            } else {
                setFeatures(builtFeatures);
            }

            if (centerLatest && builtFeatures.length > 0) {
                console.log("Center latest");
                const latestFeature = builtFeatures[0];
                centerView(latestFeature);
            } else if (centerAll && builtFeatures.length > 0) {
                console.log("Center all");
                let minX = Infinity,
                    minY = Infinity,
                    maxX = -Infinity,
                    maxY = -Infinity;

                for (let i = 0; i < builtFeatures.length; i++) {
                    const f = builtFeatures[i];
                    const cords = f.getGeometry().flatCoordinates;
                    const x = cords[0];
                    const y = cords[1];
                    if (x < minX) minX = x;
                    if (y < minY) minY = y;
                    if (x > maxX) maxX = x;
                    if (y > maxY) maxY = y;
                }

                const buffer = 2000;
                const bufferedExtent = [
                    minX - buffer,
                    minY - buffer,
                    maxX + buffer,
                    maxY + buffer,
                ];
                // Zoom to the extent
                view.fit(bufferedExtent, { duration: 1000 });
            }
        });
    };

    const centerView = (latestFeature) => {
        if (location === null || focusBoth == false) {
            const geometry = latestFeature.getGeometry();
            const extent = geometry.getExtent();
            const centerX = (extent[0] + extent[2]) / 2;
            const centerY = (extent[1] + extent[3]) / 2;

            // Animate to the new center without changing zoom
            view.animate({
                center: [centerX, centerY],
                duration: 200
            });
        } else {
            // Create an extent that includes both features
            const extent = createEmpty();
            extend(extent, latestFeature.getGeometry().getExtent());
            extend(extent, location.pointFeature.getGeometry().getExtent());

            // Fit the view to show both features
            view.fit(extent, {
                padding: [50, 50, 50, 50],
                duration: 200,
                maxZoom: 18
            });
        }
    }

    useInterval(() => {
        getLocation();
    }, 10000);

    const getLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    // Create a Ol point I can draw on the ol map below
                    const olPoint = new Point(fromLonLat([longitude, latitude]));

                    // Create a new feature with the point geometry
                    const pointFeature = new Feature(olPoint);
                    setLocation({ latitude, longitude, pointFeature });
                },
                (error) => {
                    console.error("Error getting location:", error.message);
                }
            );
        } else {
            console.log("Geolocation is not available in this browser.");
        }
    };

    const handleFocusChange = (event) => {
        const newFocusBoth = event.target.value === 'both';
        setFocusBoth(newFocusBoth);
    };

    const handlePointerClick = (e) => {
        const map = e.map;
        const features = map.getFeaturesAtPixel(e.pixel, {
            hitTolerance: 4,
        });
        const pointFeatures = features.filter(
            (feature) => feature.getGeometry() instanceof Point
        );

        if (pointFeatures.length > 0) {
            setInfoOverlayFeature(pointFeatures[0]);
        } else {
            setInfoOverlayFeature(null);
        }
    };

    const handleMapMove = (e) => {
        if (e.dragging === true) {
            setAutoCenter(false);
        }
    };

    return (<>
        <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
            <div hidden={hideTopBar}>
                <div
                    style={{
                        position: "relative",
                        flexDirection: "row",
                        padding: "0.5rem",
                        zIndex: 50,
                        backgroundColor: "#393040",
                        display: "flex",
                        color: 'white'
                    }}
                >
                    <Container style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex', flexWrap: 'wrap' }}>
                        <p>You are currently viewing a shared session from: {username}</p>
                        {!sessionEnded && <p>Last updated: {lastUpdated}</p>}
                        {sessionEnded && <p style={{ fontWeight: "bold", color: "red" }}>This session was ended by the user at {sessionEnded}</p>}
                    </Container>
                    <Button variant="success" onClick={() => { setHideTopBar(true); }}>Hide</Button>
                </div>
            </div>
            <div hidden={!hideTopBar}>
                <div style={{ position: "absolute", top: 10, left: 10, zIndex: 2000, backgroundColor: "indianRed", padding: 6, borderRadius: 4 }} >
                    <input type="radio" checked={!focusBoth} value="driver" onChange={handleFocusChange} />&nbsp;<label>Driver</label><br />
                    <input type="radio" checked={focusBoth} value="both" onChange={handleFocusChange} />&nbsp;<label>Driver and You</label>
                </div>
                <Button style={{ position: "absolute", top: 10, right: 10, zIndex: 2000 }} variant="success" onClick={() => { setHideTopBar(false); }}>Show Info</Button>
                <Button hidden={autoCenter} style={{ position: "absolute", bottom: 10, right: 10, zIndex: 2000 }} variant="danger" onClick={() => { setAutoCenter(true); centerView(features[0]); }}>Recenter</Button>
            </div>
            <div className="sessionViewMapContainer">
                <Map view={view} onClick={handlePointerClick} onPointerMove={handleMapMove}>
                    <Layers>
                        {osmLayer}
                        {targetLayer}
                        {currentLayer}
                    </Layers>
                </Map>
            </div></div>
    </>
    )
};