import React from 'react';
import { Modal, Button } from 'react-bootstrap';

/**
 * A reusable modal component using React Bootstrap
 * 
 * @param {Object} props
 * @param {boolean} props.show - Controls whether the modal is displayed
 * @param {function} props.onHide - Function to call when the modal should close
 * @param {string} props.title - The title text for the modal header
 * @param {React.ReactNode} props.children - The content to display in the modal body
 * @param {string} props.size - Modal size: 'sm', 'lg', 'xl' (optional)
 * @param {boolean} props.centered - Whether to center the modal vertically (optional)
 * @param {string} props.confirmText - Text for the confirm button (optional, defaults to "Save")
 * @param {string} props.cancelText - Text for the cancel button (optional, defaults to "Cancel")
 * @param {function} props.onConfirm - Function to call when confirm button is clicked (optional)
 * @param {boolean} props.showFooter - Whether to show the footer with buttons (optional, defaults to true)
 */
const PinPointModal = ({
  show,
  onHide,
  title,
  children,
  size,
  centered = false,
  confirmText = "Save",
  cancelText = "Cancel",
  onConfirm,
  showFooter = true
}) => {
  const handleConfirm = () => {
    if (onConfirm) {
      if (onConfirm()) {
        onHide();
      }
    }
    else{
      onHide();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size={size}
      centered={centered}
      aria-labelledby="modal-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal-title">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {children}
      </Modal.Body>

      {showFooter && (
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            {cancelText}
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            {confirmText}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default PinPointModal;