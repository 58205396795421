import React, { useState, useEffect } from 'react';
import { createProfilePictureUrl, getProfilePicture } from '../Api/UserApi';

const ProfilePicture = ({ userId, className, alt = "Profile picture" }) => {
    const [imageUrl, setImageUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Reset state when userId changes
        setLoading(true);
        setError(null);
        setImageUrl(null);

        console.log("Got user if of :", userId);
        
        const fetchProfilePicture = async () => {
            try {
                console.log("Getting the profile pic?")
                const response = await getProfilePicture(userId);
                console.log("WE have the image")
                // Create a URL from the blob response
                const url = createProfilePictureUrl(response.data);
                setImageUrl(url);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching profile picture:", err);
                setError("Failed to load profile picture");
                setLoading(false);
            }
        };

        if (userId) {
            fetchProfilePicture();
        }

        // Clean up the object URL when component unmounts or userId changes
        return () => {
            if (imageUrl) {
                URL.revokeObjectURL(imageUrl);
            }
        };
    }, [userId]);

    if (loading) {
        return <div className={className}></div>;
    }

    if (error) {
        return <div className={className}></div>;
    }

    return (
        <img 
            src={imageUrl} 
            alt={alt} 
            className={className}
            onError={(e) => {
                // Handle image loading errors
                setError("Failed to display image");
                e.target.style.display = 'none';
            }}
        />
    );
};

export default ProfilePicture;